.like {
    display: flex;
    align-items: center;
    border-top: 1px solid #363636;
    border-bottom: 1px solid #363636;
}
#home .like {
    border: none;
}
.like svg{
    font-size: 1.5em;
    cursor: pointer;
}
.like p{
    margin-left: 1em;
}