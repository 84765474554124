#profile {
	width: 50%;
	margin: 0 auto;
}
.profile-header {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 1em;
	border-bottom: 1px solid #363636;
}
.profile-header img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin-right: 2em;
}
.new-photo {
	padding: 1em;
	border-bottom: 1px solid #363636;
}
.photos-container {
	display: flex;
	flex-wrap: wrap;
}
.photo {
	width: 32%;
	margin: 0.3%;
}
.photo img {
	width: 100%;
}
.actions {
	display: flex;
	justify-content: space-around;
	padding: 10px;
}
.actions svg {
	cursor: pointer;
}
.edit-photo {
	margin-bottom: 1em;
}
.edit-photo img {
	width: 100%;
	margin-bottom: 1em;
}
@media (max-width: 768px){
	.photos-container{
		display: flex;
		width: 100%;
		flex-wrap: wrap;
	}
	.photos-container img{
		width: 100%;
	}
	.photo{
		width: 100%;
	}
}