.photo-item img{
    width: 100%;
}
#home .photo-item h2{
    margin-bottom: .2em;
}
.photo-author {
    text-align: left;
}
.photo-author a{
    font-weight: bold;
}