#nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #000;
	border-bottom: 1px solid #363636;
	padding: 0.1em 1em;
}
#search-form {
	margin: 0;
	position: relative;
	width: 20%;
}
#search-form svg {
	position: absolute;
	top: 10px;
	left: 18px;
}
#search-form input {
	padding-left: 2.5em;
	border: none;
	border-radius: 5px;
	width: calc(100vw - 50vw);
	margin: 0 1em;
}
#nav-links {
	display: flex;
	align-items: center;
}
#nav-links li {
	margin-right: 1em;
}
#nav-links span {
	cursor: pointer;
}
#nav-links svg {
	font-size: 1.5em;
}
.logo {
	max-width: 250px;
	min-height: 50px;
}

#btn-mobile {
	display: none;
}
@media (max-width: 768px) {
	#nav-links {
		display: block;
		position: absolute;
		top: 50px;
		right: 0px;
		width: 100%;
		height: 0px;
		transition: 0.6s;
		background-color: #413e3e;
		opacity: 90%;
		z-index: 1000;
		visibility: hidden;
		overflow-y: hidden;
	}
	.active #nav-links {
		height: calc(100vh - 70px);
		visibility: visible;
		overflow-y: auto;
	}
	#nav-links ul {
		margin: auto;
		padding: 5em;
	}
	#nav-links li {
		padding: 1em;
		margin: 1em;
		border-bottom: 1px solid #696868;
	}
	#nav-links li:hover {
		background: #363636;
	}
	#btn-mobile {
		display: block;
		padding: 0.5rem 1rem;
		font-size: 1rem;
		border: none;
		background: none;
		cursor: pointer;
		gap: 0.5rem;
	}
	#hamburguer {
		color: #c02525;
		display: block;
		border-top: 4px solid;
		width: 25px;
	}
	#hamburguer::after,
	#hamburguer::before {
		background: currentColor;
		content: "";
		display: block;
		width: 25px;
		height: 4px;
		margin-top: 5px;
		transition: 0.3s;
		position: relative;
	}
	.active #hamburguer {
		border-top-color: transparent;
	}
	.active #hamburguer::before {
		transform: rotate(125deg);
	}
	.active #hamburguer::after {
		transform: rotate(-125deg);
		top: -9px;
	}
	#search-form{
		margin: 0;
	}
	
		
	#search-form svg {
		margin: 0px;
		padding: 0;
	}
}
