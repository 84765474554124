.message {
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
}

.message.error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.message.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
