#search {
	width: 50%;
	margin: 0 auto;
	padding-top: 2em;
}

#search h2 {
	text-align: center;
}
#search .btn {
	display: block;
	max-width: 80px;
	text-align: center;
	margin: 0.5em 0 2em 0;
}
