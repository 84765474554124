#edit-profile {
	border: 1px solid #363636;
	background-color: #000;
	padding: 1.5em 2em;
	max-width: 40%;
	margin: 2em auto;
	text-align: center;
}

.subtitle {
	color: #ccc;
}

.profile-image {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	margin-bottom: 1em;
}
